@use 'base-styles/abstracts/spacing' as s;

$dg-touch-target-size: 22px;
$dg-checkboxes-size: 22px;
$dg-checkboxes-label-padding-left-right: 22px;
$dg-border-width: 2px;
$dg-focus-width: 2px;
$dg-border-width-mobile: 1px;
$dg-border-colour: green;
$dg-show-breakpoints: false !default;
$dg-is-ie8: false !default;
$dg-ie8-breakpoint: desktop !default;

.dg-checkboxes__item {
  display: block;
  position: relative;
  min-height: $dg-checkboxes-size;
  margin: 20px 0;
  padding-left: $dg-checkboxes-size;
  clear: left;
}

.dg-checkboxes__item:last-child,
.dg-checkboxes__item:last-of-type {
  margin-bottom: 0;
}

.dg-checkboxes__input {
  $input-offset: ($dg-touch-target-size - $dg-checkboxes-size) / 2;

  cursor: pointer;

  // IE8 doesn’t support pseudo-elements, so we don’t want to hide native
  // elements there.
  @include dg-not-ie8 {
    position: absolute;

    z-index: 1;
    top: $input-offset * -1;
    left: $input-offset * -1;

    width: $dg-touch-target-size;
    height: $dg-touch-target-size;
    margin: 0;

    opacity: 0;
  }

  @include dg-if-ie8 {
    margin-top: 10px;
    margin-right: $dg-checkboxes-size / -2;
    margin-left: $dg-checkboxes-size / -2;
    float: left;
  }
}

.dg-checkboxes__label {
  display: inline-block;
  margin-bottom: 0;
  padding: 0 0 0 8px;
  // remove 300ms pause on mobile
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  // [ ] Check box
  &::before {
    content: '';
    //box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    min-width: 22px;
    height: 22px;
    border-width: var(--border-width);
    border-style: solid;
    padding: 1px 0;
    box-shadow: 0 0 3px 0 var(--tone-400);
    border-radius: 2px;
  }

  // ✔ Check mark
  .icon {
    position: absolute;
    left: 5px;
    top: 5px;
    transition: opacity var(--ease-transition);
    opacity: 0;
    font-size: 11px;

    &::before {
      color: var(--user-input-selected-color);
    }
  }
}

.dg-checkboxes__input:checked {
  + .dg-checkboxes__label::before {
    background: var(--user-input-selected-color);
    border-color: var(--user-input-selected-color);
  }
  + .dg-checkboxes__label {
    .icon {
      opacity: 1;

      &::before {
        color: var(--user-input-selected-contrast-color);
      }
    }
  }
}

// Disabled state
.dg-checkboxes__input:disabled,
.dg-checkboxes__input:disabled + .dg-checkboxes__label {
  cursor: default;
}

.dg-checkboxes__input:disabled + .dg-checkboxes__label {
  opacity: 0.5;
}

// =========================================================
// Conditional reveals
// =========================================================

$conditional-border-width: $dg-border-width-mobile;
// Calculate the amount of padding needed to keep the border centered against the checkbox.
$conditional-border-padding: ($dg-checkboxes-size / 2) -
  ($conditional-border-width / 2);
// Move the border centered with the checkbox
$conditional-margin-left: $conditional-border-padding;
// Move the contents of the conditional inline with the label
$conditional-padding-left: $conditional-border-padding +
  $dg-checkboxes-label-padding-left-right;

.dg-checkboxes__conditional {
  margin-bottom: 20px;
  margin-left: $conditional-margin-left;
  padding-left: $conditional-padding-left;

  .js-enabled &--hidden {
    display: none;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
