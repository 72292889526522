// DMA specific icons
@font-face {
  font-family: 'icomoon-dma';
  src: url('../fonts/icomoon-dma.eot?ons7fi');
  src: url('../fonts/icomoon-dma.eot?ons7fi#iefix') format('embedded-opentype'),
    url('../fonts/icomoon-dma.ttf?ons7fi') format('truetype'),
    url('../fonts/icomoon-dma.woff?ons7fi') format('woff'),
    url('../fonts/icomoon-dma.svg?ons7fi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-dma'],
[class*=' icon-dma'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-dma' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dma-washing-machine:before {
  content: '\e918';
}
.icon-dma-eye:before {
  content: '\e917';
}
.icon-dma-tick-success:before {
  content: '\e904';
  color: #fff;
}
