app-root,
storybook-dynamic-app-root,
mat-dialog-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: var(--tone-700);
  }

  h1,
  .h1 {
    margin-bottom: 12px;
  }

  .text-white {
    p,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: var(--tone-100);
    }
  }

  .title {
    position: relative;
    text-align: center;
  }
}

.align-right {
  text-align: right;
}
