html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

body {
  height: 100%;
}

app-root {
  height: 100%;
  margin: 0;
}

.clearfix {
  display: block;
}
.hidden {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
