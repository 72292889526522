.upsell-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.upsell-button {
  /* Dktp Button 3.0 */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;

  width: 276px;
  height: 50px;

  /* Grayscale/100 */

  border: 1px solid $white;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  background: transparent;
  color: $white;

  //styleName: DESKTOP/Button txt dtop;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
