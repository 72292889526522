.check-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.check-label {
  display: flex;
  margin: 10px;
  color: $white;
}
