.call-us {
  position: relative;
  margin: 40px 0 20px 0;
  &__number {
    margin: 40px 0 0 0;
  }
}

.icon-round {
  background: var(--tone-200);
  border-radius: 30px;
  height: 24px;
  width: 24px;
  margin: 0px auto;
  -ms-transform: scale(4);
  transform: scale(3);
}

.model-card {
  display: block;
  background: var(--tone-100);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

@include media-breakpoint-up(sm) {
  .call-us {
    margin: 20px 0;

    &__number {
      float: left;
      margin: 0;
    }
  }

  .icon-round {
    margin: 20px 40px 0 0;
    float: left;
  }
}
