.cross-sell-card {
  background: transparent;
  max-width: 340px;
  margin: auto;
}

.cross-sell-container {
  background-color: $purple;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  min-height: 528px;
}

.cross-sell-icon {
  margin-bottom: 30px;
}

.cross-sell-title {
  font-size: $typog-24-font;
  font-weight: $typog-600-weight;
  line-height: $typog-32-lineheight;
  letter-spacing: 0.016em;
  text-align: center;
  color: $white;
  margin-bottom: 10px;
}

.cross-sell-subtitle {
  font-size: $typog-20-font;
  font-weight: $typog-600-weight;
  line-height: $typog-24-lineheight;
  letter-spacing: 0px;
  text-align: center;
  color: $white;
  margin-bottom: 20px;
}

.cross-sell-check-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.cross-sell-shield-check {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.shield-check-label {
  font-size: $typog-20-font;
  font-weight: $typog-600-weight;
  line-height: $typog-24-lineheight;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 5px;
  color: $white;
}

.fab-row {
  display: flex;
  justify-content: center;
}

.fab-container {
  width: 308px;
  display: flex;
  position: absolute;
  align-items: center;
}

.fab-button {
  display: flex;
  align-items: center;
  width: 308px;
  height: 55px;
  border-radius: 60px;
  background-color: #6f4ca2;
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 2px solid #ffffff;
}

.fab-plus {
  display: flex;
  margin-left: 10px;
}

.fab-plus > p {
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.fab-modal {
  justify-content: center;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  text-align: center;
}

.fab-modal-open {
  margin-top: -80px;
}

.fab-modal-close {
  position: absolute;
  left: 15px;
}
