.container {
  @include make-container();
  @include make-container-max-widths();
}

.container-fluid {
  @include make-container();
  max-width: 1084px;
  max-width: 1124px;
}

.row {
  @include make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include make-no-gutters();

@include make-grid-columns();

// spacer
.row--spacer {
  margin-top: 30px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
// spacer small
.row--spacer-small {
  margin-bottom: 10px;
  margin-top: 10px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
// top
.row--spacer-top {
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 45px;
  }
}

// top - small
.row--spacer-top-small {
  margin-top: 10px;

  @include media-breakpoint-up(lg) {
    margin-top: 20px;
  }
}
// top - medium
.row--spacer-top-medium {
  margin-top: 15px;

  @include media-breakpoint-up(lg) {
    margin-top: 30px;
  }
}
// top - large
.row--spacer-top-large {
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    margin-top: 60px;
  }
}

// bottom
.row--spacer-bottom {
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 45px;
  }
}
// bottom - small
.row--spacer-bottom-small {
  margin-bottom: 10px;
}
// bottom - mediun
.row--spacer-bottom-medium {
  margin-bottom: 15px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }
}
// bottom - large
.row--spacer-bottom-large {
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 70px;
  }
}

.row--padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.no-padding {
  padding: 0;
}

.row--padding-large {
  padding-top: 30px;
  padding-bottom: 30px;
}

.row--padding-top-large {
  padding-top: 30px;
}
.row--padding-bottom-large {
  padding-bottom: 30px;
}

.margin-auto {
  margin: auto;
}
