@use 'base-styles/abstracts/typography' as t;
@use 'base-styles/abstracts/breakpoints' as bp;

.bg--page {
  background: var(--page-bg);
}

.bg--page-alt {
  background: var(--page-bg--alt);
}

.bg--blur {
  background-position: center;
  background: url('/assets/images/bg-blur.png') no-repeat,
    rgba(255, 255, 255, 0.5);
  background-size: cover;

  @include bp.breakpoint(md) {
    background-position: center;
    background: url('/assets/images/bg-blur-large.png') no-repeat,
      rgba(255, 255, 255, 0.5);
    background-size: cover;
  }
}

.bg--primary {
  background: var(--hero-color);
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p {
    color: var(--hero-contrast-color);
  }
}

.icon-block {
  width: 61px;
  height: 61px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  margin-right: 10px;

  i {
    color: var(--text-color);
    font-size: 24px; // TODO WL: Repair icon looks too small - Chimmy to follow up
  }

  @include bp.breakpoint(lg) {
    margin-right: 20px;
  }

  @include bp.breakpoint(xl) {
    margin-right: 30px;
  }

  &.icon-block--grey {
    background: rgba(255, 255, 255, 0.45);
  }
}
