@use 'base-styles/base-styles';
@use 'base-styles/themes/ao' as theme;

html,
body {
  @include theme.ao();

  --max-input-width: auto;
  --btn-max-width: auto;

  .forgot-password-container {
    top: -108px !important; // TODO WL: Override for now. But needs html rewrite for login layout.
  }
}
