@use 'base-styles/abstracts/breakpoints' as bp;

.btn {
  text-align: center;

  @include bp.breakpoint(md) {
    width: auto;
    display: inline-block;
    min-width: 256px;
  }

  // Disabled comes first so active can properly restyle
  &.disabled:not(.btn-active),
  &:disabled:not(.btn-active) {
    cursor: not-allowed;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }

  &.btn--block {
    width: 100%;
  }
}
