@use 'base-styles/abstracts/forms' as f;
@use 'base-styles/abstracts/spacing' as s;
@use 'base-styles/abstracts/typography' as t;

.legacy-form-builder {
  &.form-input,
  &.form-group {
    margin-bottom: 20px;
    position: relative;

    span + .form-input-feedback {
      margin-bottom: 0;
    }

    //a {
    //  // TODO WL: this style was applied for a login form in shared/feature-authentication.. log-in.component. Remove if not used.
    //  margin-top: 10px;
    //  display: block;
    //  @include font-size(14); // WL: Use shared ui mixin
    //  // letter-spacing: 0.8px; // removed WL
    //}

    &__feedback {
      position: relative;
    }

    display: flex;
    flex-direction: column;

    label,
    .label {
      display: inline-block;
      @include f.text--label();
      @include s.margin-bottom(1);
    }

    .form-control {
      width: 100%;
      position: relative;

      .icon {
        @include f.validation-icon();
      }

      textarea,
      select {
        // TODO WL: seems like this could go in shared styles
        width: 100%;
        resize: none;
      }

      &__hint {
        @include f.text--hint();
        text-align: left;
      }
    }

    &__hint {
      @include f.text--hint();
      @include s.margin-top(0.5);
    }

    &__error {
      @include f.text--error();
      display: flex;
      @include s.margin-top(0.5);
    }

    &.is-invalid .form-control {
      input:not(.mat-input-element):not(.pure):not([type='radio']),
      textarea,
      select {
        transition: all 0.8s ease-in-out;
        @include f.box--invalid();
      }

      .icon.icon-error-circle {
        @include f.validation-icon--invalid();
      }
    }

    &.is-valid .form-control {
      input:not(.mat-input-element):not(.pure):not([type='radio']),
      textarea,
      select {
        @include f.box--valid();
      }

      .icon.icon-tick-circle {
        @include f.validation-icon--valid();
      }
    }

    // TODO WL: what is this for? (presume validation icons)
    .form-control:after {
      content: '';
      transition: all 0.2s ease-in-out;
      transform: scale(0);
      z-index: 1;
    }

    // TODO WL this means something else in base-styles now - rename usages to closer align with sales
    .form-input-feedback {
      @include f.text--error();
      display: block;
      margin: 10px 0;
    }

    // TODO WL: this should be agreed as standard requirement and moved to shared styles
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill:active,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
      // this prevents the color of the field from changing when it has been autofilled
      // for some reason setting the background property does not work
      // this trick effectively changes the background color though
      // 1000px is an arbitrarily high number just so we never only partially cover the field
      -webkit-box-shadow: 0 0 0 1000px var(--input-background-color) inset !important;
    }

    textarea {
      // TODO WL: this should be agreed if it is standard and possibly moved to shared styles
      min-height: 105px;
    }

    /*tooltip related */
    //TODO WL: can we use reusable tooltips as per sales?
    .tooltip {
      position: absolute;
      top: 1px;
      right: 8px;
      text-align: center;
      background-color: var(--primary-interactive-color);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      font-size: 14px;
      line-height: 26px;
      cursor: default;

      &:before {
        content: '?';
        //todo: use icon instead of text
        font-family: Arial, sans-serif;
        font-weight: bold;
        color: var(--primary-interactive-contrast-color);
      }

      &:hover {
        + p {
          display: block;
          z-index: 1;
          transform-origin: 100% 0;
          -webkit-animation: fadeIn 0.3s ease-in-out;
          animation: fadeIn 0.3s ease-in-out;
        }
      }

      + p {
        display: none;
        text-align: left;
        padding: 20px;
      }
    }
  }
}
